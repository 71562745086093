.event-category-tag{
    background: #ffffff; 
    padding: 5px 10px;
    font-weight: 600;
    font-size: 1rem;
}
.icon{
    margin-left: 8px;
    margin-top:10px;
    border: 1px solid;
    border-radius: 20px;
    padding: 2px;
    cursor: pointer;
}
.css-1tf0sda{
    margin: 0px !important;
}
.icon.mail-icon{
 width: 20px;
 height: 20px;   
}
.icon.hub-icon{
    width: 20px;
    height: 20px;
}
.navigationLink:hover, .navigationLink:active, .navigationLink:focus {
    color: #008DFC;
    transition: 0.3s ease-in;
}
.custom-indicator button:hover, .custom-indicator button:active, .custom-indicator button:focus{
    color: #fff;
}
.custom-indicator button .MuiSvgIcon-root{
    font-size: 20px;
}
.logo-name-footer {
    width: 134px;
    height: 20px;
    margin-left: 50px;
    /* margin-top: 5px; */
}
.navigationLink:hover, .navigationLink:active, .navigationLink:focus {
    color: #008DFC;
    transition: 0.3s ease-in;
}
.custom-indicator button:hover, .custom-indicator button:active, .custom-indicator button:focus{
    color: #fff;
}
.custom-indicator button .MuiSvgIcon-root{
    font-size: 20px;
}
.logo-name-footer {
    width: 134px;
    height: 20px;
    margin-left: 50px;
    /* margin-top: 5px; */
}

.logo-footer {
    max-width: 17%;
    max-height:100%;
    width:60px;
    height:60px;
    margin: 0;
    border-radius: 0;
}
.logo-watermark-footer {
    max-width:100%;
    max-height: 20px;
    width:140px;
    height:17px;
    margin-left:48px;
    margin-top:8px;
    border-radius:0
}
.footer-menu {
    color:#fff;
    text-decoration:none;
    font-size:1.125rem;
}

@media screen and (max-width: 767px){
    .logo-footer{
        max-width: 26%;
    }
    .logo-watermark-footer {
        max-height: 20px;
        height:17px;
    }
    .footer-menu {
        color:#fff;
        text-decoration:none;
        font-size:.8rem;
    }
}

.faq-answers {
    a {
        color:#0078d6;
    }
}
.MuiSnackbarContent-root {
    background-color: transparent;
}