.event-details-section {
    display: flex;
    flex-direction: row;
}

.event-details-section .icon-div {
    padding: 5px 20px 5px 0;
}

.carousel-event-category-tag {
    background-color: #fff;
    padding: 15px 15px;
    font-size: 16px;
    letter-spacing: .5px;
}
.reason-box{
    padding-top: 1em;
    font-size: 10px;
}
.MuiFormLabel-root {
    font-size: 15px;
}

/* .carousel-container {
    display:block
} */
/* .carousel-item-class {
    margin: 0 10px;
} */

.react-multi-carousel-list {
    position:initial;
}
.react-multiple-carousel__arrow {
    background: transparent;
}
.react-multiple-carousel__arrow--right {margin-right: 30px;}
.react-multiple-carousel__arrow--left {margin-left: 30px;}
.react-multiple-carousel__arrow::before {
    color: #828181;
}
.react-multiple-carousel__arrow:hover {
    background: transparent;
}
.action-buttons {
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 400;
    border-radius: 0;
    height: 52px;
    background-color: #0078D6;
}
