/* .search-form .MuiSelect-iconStandard{
    top: calc(50% - 0.8em) !important;
} */
.search-form{
    box-shadow: none;
    border-bottom: 1px solid #000;
}
/* #simple-select{
    margin-top: 12px;
} */
.image-upload-button {
    border-radius: 23px;
    width: 70%;
}
.image-upload-button.feedback-upload{
    width: fit-content;
}
.image-name-style {
    font-size: 13px;
    color: #1976d2;
    border-radius: 7px;
    width:70%;
}
.MuiPopper-root-MuiPickersPopper-root {
    z-index:9999
}
.modal-title {
    background-color: #1A1A1A;
    color: #FFFFFF;
    text-align: center;
    padding:10px;
    font-size:22px !important;
}
.MuiDialogContent-root {
    border-bottom: none;
}
.MuiModal-root-MuiDialog-root .MuiDialogActions-root {
    padding: 30px;
}
.mui-modal-button {
    width: 350px;
    border-radius: 0;
}
.filterSelect {
    text-align: center;
    background-color: #1976d2;
    height: 50px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    .MuiSelect-icon {
        display: none !important;
    }
    .MuiSelect-select-MuiInputBase {
        padding: 10.5px 25px;
    }
}
.carousel-item-padding-40-px{}
.carousel.carousel-slider .control-arrow {
    padding: 0;
    margin-block: 10%;
}
.MuiFormLabel-root {
    font-size: 18px;
    color: #131822;
}

.location-search-box {
    min-width: 40%; 
    padding-left: 9px; 
    font-size: 18px;
    color: #505050;
}
.filterButton {
    width: 50%;
    background-color: #1976d2;
    margin-left: 5px;
}
@media screen and (max-width: 767px){
    .filterButton {
        width: 100%;
        display: inline;
        
    }
    
}
