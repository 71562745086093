.download-button {
    background-color: #fff;
    color: #007bff;
    padding: 16px 16px;
}

.download-button:hover {
    background-color: #fff;
    transition: 0.3s ease-in;
    opacity: .7;
}

.Mui-expanded {
    transform: none !important;
}

.banner-container {
    padding: 40px 50px;
    display: flex;
    align-items: center;
    background-color: #F1F0F0;
    height: 'auto';

    .banner {
        margin: 10px;

        .banner-content {
            padding: 25px 40px;
        }
    }
}
.faq-search-container {
    margin-bottom: 8px;
}

@media only screen and (max-width: 767px) {
    .banner-container {
        padding: 0;

        .banner {
            margin: 0;

            .banner-content {
                padding: 25px 25px;

                .banner-heading {
                    font-size: 2.75rem;
                }
            }
        }

    }

    .faq-search-container {
        padding: 0 25px;
    }
}
.css-m31llf-MuiInputBase-root-MuiInput-root {
    font-size: 24px;
}

.accordion-summary-header .MuiAccordionSummary-content{
    display: flex;
    justify-content: space-between !important;
}
.added-link {
    a {
        color: #2183d7;
    }
}