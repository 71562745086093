@tailwind base;
@tailwind components;
@tailwind utilities;

/*new font family*/
@font-face {
  font-family: 'mcregular';
  src: url(./static/fonts/mbc-regular.otf);
  src: url(./static/fonts/MBCorpoATextCond-Regular.otf?#iefix) format('embedded-opentype'),
       url(./static/fonts/MBCorpoATextCond-Regular.otf) format('otf'),
       /* url(./static/fonts/corpa-regular.ttf) format('truetype'), */
       url(./static/fonts/mbc-regular.otf) format('otf'),
       url(./static/fonts/mbc-regular.ttf) format('truetype'); font-display:swap;
}
 

@font-face {
  font-family: 'mbcorpos';
  src: url(./static/fonts/mbcorpos.otf),
      url(./static/fonts/mbcorpos.ttf) format('ttf'); font-display:swap;
}

p, ul li, ol li, h6{font-family: 'mbcorpos' !important; font-size:20px}
h1, h2, h3, h4, h5{font-family:'mcregular' !important; line-height: 1; font-weight: 400}
h2 {font-size: 45px !important}
h6{font-weight:600 !important}

.react-multiple-carousel__arrow{
  background-color: rgba(0,0,0,0.7) !important;
}
.react-multiple-carousel__arrow::before, .react-multiple-carousel__arrow::after{
  color: #ffffff !important;
}

