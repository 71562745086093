.dark-bg{ background-position: center center;background: url('../../static/images/impact-bg.png') no-repeat; color: #fff; background-color: #000;}
.dark-bg::after {position: absolute;}
.dark-bg-footer {background-position: center center;background-color:#000;  color: #fff; background-color: #000;}
.hyperlink{cursor: pointer; text-decoration: underline;}
.subtext{color: #d0d0d0;}
.socialImpactIcon{border-radius: none; height: 40px;}
.custom-accordion{background-color: transparent; color: #d0d0d0;}
.react-multi-carousel-dot-list.custom-dot-list-style{padding: 2rem; position: relative;
button {
    width:14px;
    height:14px;
    margin-right: 15px;
    border-style: none;
    background: #D9D9D9;
}
.react-multi-carousel-dot--active button{background:#008dfc}
}

.react-multiple-carousel__arrow--left{left: calc(0% + 1px) !important;}
.react-multiple-carousel__arrow--right{right: calc(0% + 1px) !important;}
.MuiInputBase-root-MuiOutlinedInput-root{padding-right:0!important}
.searchButton {border-radius: 0; padding: 16px 15px; background-color: #0078D6}
.searchButton:hover {background-color: #176ef8; }

.navigation-button {
    background: transparent !important;
    border-radius: 0;
    opacity: 1 !important;
    transition:none !important;
}
.navigation-button:hover {
    opacity: 1 !important;
    transition:none !important;
    background: transparent !important;
}
.paper-banner{
    height: 418px;
}
.carousel-control-prev{
    background: #000;
    width: 70px;
    height: 60px;
    position: relative;
    top: 127px;
    right: -4px;
    padding-right: 29px;
    padding-top: 22px;
}
.carousel-control-prev:hover{
    background: #007bff;
}
.carousel-control-prev-icon{
    background: url('../../static/images/long-arrow-left.png');
    position: absolute;
    width: 32px;
    height: 10px;
    background-size: contain;
    
}
.carousel-control-next{
    right: 0;
    width: 70px;
    height: 60px;
    background: #000;
    padding-right: 29px;
    padding-top: 22px;
    position: relative;
    top: 127px;
    left: 1044px;
}
.carousel-control-next-icon{
    background: url('../../static/images/long-arrow-right.png');
    position: absolute;
    width: 32px;
    height: 10px;
    background-size: contain;
}
.carousel-container{
    .MuiCardHeader-root {
        margin-left:75%!important;
    }
}
.carousel-control-next:hover{
    background: #007bff;
}
/* .carousel-container { display: grid !important} */
.userImage{
    width: 180px;
    height: 180px;
    margin-top: 40px;
    margin-left: 40px;
    margin-right: 20px;
}
.img-content{
    height: 100%;
}
.css-tfdo6c-MuiTypography-root {
    margin-bottom: 0px;
}
.MuiDataGrid-root {
    border:none;
    .MuiDataGrid-row {
        background-color: #4b4a4a;
        color: #fff;
        margin-bottom: 20px;
    }
    .MuiDataGrid-row:hover {
        background-color: #4b4a4a;
    }
    .MuiDataGrid-row.Mui-selected {
        background-color: #4b4a4a;
    }
    .MuiDataGrid-row.Mui-selected:hover, .css-az8st9-MuiDataGrid-root .MuiDataGrid-row.Mui-selected.Mui-hovered {
        background-color: #4b4a4a;
    }
    .MuiDataGrid-row--editing .MuiDataGrid-cell {
        background-color: #4b4a4a;
        color: #fff;
    }
    .MuiDataGrid-editInputCell {
        color: #fff;
    }
    .MuiDataGrid-cell.MuiDataGrid-cell--editing {
        background-color: #4b4a4a;
    }
    .MuiDataGrid-columnHeader:focus {
        outline: none !important;
        
    }    
    .table-header {
        border-right: 1px solid #414040;
        height: 30px !important; 
    }
    
    /* .MuiDataGrid-cell {
        max-height: 40px !important;
        min-height:40px !important;
    } */
}
.MuiDataGrid-columnHeaderRow {
    height: 20px !important;
}
.image-name-block {
    color: #fff;
    border-radius: 7px;
    border: 1px solid #C9C9C9;
    /* width:85%; */
}
.MuiImageListItemBar-root {background-color: #eae9e9; }
.MuiImageListItemBar-titleWrap {padding-left: 6px;}
.spocs-add-button {text-transform: capitalize; border-radius: 0; height: 55px; font-weight:600; font-size:18px; margin-top: 8px;}
.spocs-edit-button {text-transform: capitalize; border-radius: 0; height: 55px; font-weight:600; font-size:18px; margin-top: 8px; margin-right:8px}
.testimonials-add-button {text-transform: capitalize; border-radius: 0; height: 55px; font-weight: 600; font-size: 18px; margin-top: -55px; position:relative; left:89% }
.highlights-edit-button {text-transform: capitalize; border-radius: 0; height: 55px; font-weight:600; font-size:18px; margin-top: 8px; position:absolute; right:5% }
.card-edit-icon {width: 64px; float:right; }
.home-page-banner-heading { 
    padding-top: 64px;
    padding-left:80px;
    color: #fff;
    width: 400px;
    font-size: 64px!important; 
    line-height:1;
}
.css-tygchf-MuiPaper-root {
    height: 100% !important;
}
.home-page-banner-subheading {
    padding-left: 80px;
    color: #fff;
    width: 360px;
    line-height:1.1;
    font-weight:400!important;
    font-size:1.5rem!important;
}
@media screen and (max-width: 767px){
    .change-heading-for-mobile {
        padding-left: 0 !important;padding-right: 0 !important
    }
    .paper-banner{
        height: 100% !important;
    }
    .home-page-banner-heading { 
        padding-top: 24px;
        padding-left:20px;
        font-size: 22px !important;
    }
    .css-tygchf-MuiPaper-root {
        height: auto !important;
    }
    .css-aq8pb7-MuiSvgIcon-root {
        height: 15px;
        width: 15px;
    }
    .css-8mwd4x-MuiButtonBase-root-MuiButton-root {
    margin-top: 10px;
    margin-bottom: 10px;
    }
    .home-page-banner-subheading { 
        padding-left: 20px;
        font-size: 18px !important;
    }
    .testimonials-add-button {
        margin-top: -38%;
        left:76%;
    }
    
    }

    .spocs-profile-image{
        height: 160px;
        width: 160px;
        margin:  0px auto
    }

